import { HTMLAttributes } from "react"
import styled from "styled-components"

import {
  greyScale,
  semanticEmergency,
  semanticGood,
  semanticWarning,
} from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

const COLOR = {
  good: semanticGood,
  bad: semanticEmergency,
  warning: semanticWarning, //changed color from #ECCB99 to #DA9422
  grey: greyScale[200],
}

type colorType = "good" | "bad" | "warning" | "grey"

export function DeviceStatusIcon({
  color = "grey",
  children,
  size,
  ...props
}: { size?: string; color?: colorType } & HTMLAttributes<React.ReactNode>) {
  return (
    <Container {...props}>
      <StatusIcon color={color} size={size} />
      <MText variant="bodyS" color="secondary">
        {children}
      </MText>
    </Container>
  )
}

const StatusIcon = styled.div<{ size?: string; color: colorType }>`
  height: ${(props) => {
    return props.size || spacing.S
  }};
  width: ${(props) => {
    return props.size || spacing.S
  }};
  border-radius: 50%;
  margin-right: ${spacing.XS2};
  display: inline-block;
  background-color: ${(props) => {
    return COLOR[props.color]
  }};
`

const Container = styled.div`
  display: flex;
  align-items: center;
`

const Label = styled.span`
  white-space: nowrap;
`
